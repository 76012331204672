<template>
  <div class="base-form">
    <el-form
      ref="ruleFormRef"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="活动名称:" prop="name">
        <div class="active-name">
          <el-input v-model="ruleForm.name" />
        </div>
      </el-form-item>
      <el-form-item label="发放时间:" prop="distributionType">
        <el-radio-group v-model="ruleForm.distributionType">
          <el-radio :label="1">立即发放</el-radio>
          <el-radio :label="2">
            <div class="open-time">
              <span>定时发放</span>
              <template v-if="ruleForm.distributionType == 1">
                <el-date-picker
                  disabled
                  v-model="ruleForm.sendTime"
                  type="datetime"
                  placeholder="选择日期"
                />
              </template>
              <template v-else>
                <el-form-item prop="sendTime">
                  <el-date-picker
                    v-model="ruleForm.sendTime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="YYYY-MM-DD HH:mm:ss"
                  />
                </el-form-item>
              </template>
            </div>
          </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发放对象:" prop="distributionScope">
        <el-radio-group v-model="ruleForm.distributionScope">
          <el-radio :label="1">选择客户</el-radio>
          <el-radio :label="2">全部客户</el-radio>
        </el-radio-group>
        <div v-if="ruleForm.distributionScope == 1" class="opt-client-list">
          <template v-for="(item, index) in ruleForm.userIds">
            <div class="user-wrap">
              <div class="user-item">
                <div class="avatar">
                  <template v-if="item.userAva != 'default_ava.png'">
                    <img :src="item.userAva" alt="" />
                  </template>
                  <template v-else>
                    <img src="@/assets/img/main/base/default_avatar.png" alt="" />
                  </template>
                </div>
                <div class="uname">
                  <div class="nick">{{ item.userRealname }}</div>
                  <div class="phone">{{ item.userMobile }}</div>
                </div>
              </div>
              <div @click="handleDeleteClick(item, index)" class="delete">
                <el-icon>
                  <Delete />
                </el-icon>
              </div>
            </div>
          </template>
          <div @click="handleAddClientClick" class="add-btn">
            <el-icon>
              <Plus />
            </el-icon>
            添加客户
          </div>
        </div>
      </el-form-item>
      <el-form-item label="添加优惠券:" prop="couponList">
        <div class="add-coupon">
          <div @click="handleAddCouponClick" class="btn">选择优惠券</div>
          <div>最多支持添加15张优惠券：已选 现金券{{couponTotal.cashCoupon}}｜折扣券{{couponTotal.discontCoupon}}｜兑换券{{couponTotal.exchangeCoupon}}</div>
        </div>
        <el-scrollbar height="350px">
          <el-tabs v-model="activeStatus" type="card" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane v-for="(item,index) in tabStatusList" :key="index" :label="item.label" :name="item.value">
            </el-tab-pane>
            <div class="coupons-list">
              <page-content
                :contentTableConfig="contentTableConfig"
                :isButtonStyle="true"
                :dataCount="dataCount"
                :dataList="dataList"
                @selectionChange="selectionChange"
              >
                <template #distributionType="scope">
                  <template v-if="scope.row.distributionType==1">免费获取 </template>
                  <!-- <template v-else>订单满 {{ scope.row.thresholdPrice }}元</template> -->
                  <template v-else>购买获取</template>
                </template>
                <template #total="scope">
                  <span>{{ scope.row.surplus }}</span>
                  <span class="line">｜</span>
                  <span>{{ scope.row.total }}</span>
                  <p v-if="scope.row.surplus==0" style="color: red;">（剩余量不足）</p>
                </template>
                <template #validityType="scope">
                  <template v-if="scope.row.validityType == 1">
                    {{ scope.row.startDate + "至" + scope.row.endDate }}
                  </template>
                  <template v-else>
                    <template v-if="scope.row.validityType == 2">
                      领取当天后{{ scope.row.days }}天有效
                    </template>
                    <template v-else> 领取次日后{{ scope.row.days }}天有效 </template>
                  </template>
                </template>
                <template #claimRestrictions="scope">
                  <template v-if="scope.row.claimRestrictions == 1">
                    不限制
                  </template>
                  <template v-else>
                    <template v-if="scope.row.claimRestrictions == 2">
                      每人限领{{ scope.row.limitNumber }}张
                    </template>
                    <template v-if="scope.row.claimRestrictions == 3">
                      每月限领{{ scope.row.limitNumber }}张
                    </template>
                    <template v-if="scope.row.claimRestrictions == 4">
                      每年限领{{ scope.row.limitNumber }}张
                    </template>
                  </template>
                </template>
                <template #discount="scope">
                  <p>{{ scope.row.type==1?'¥'+scope.row.discount: scope.row.type==2?scope.row.discount+'折':'-'}}</p>
                </template>
                <template #quantity="scope">
                  -
                </template>
                <template #pagination>
                  <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pagesize"
                  :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="dataCount"
                  @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </template>
              </page-content>
            </div>
          </el-tabs>
        </el-scrollbar>
      </el-form-item>

      <el-form-item label="归属门店：" prop="dpId">
        <el-select
          v-model="ruleForm.dpId"
          class="m-2"
          placeholder="选择门店"
          size="large"
        >
          <el-option
            v-for="item in shopList"
            :key="item.shopId"
            :label="item.shopName"
            :value="item.shopId"
          />
        </el-select>
      </el-form-item>

      <el-form-item label="通知方式:" prop="name">
        <el-checkbox v-model="ruleForm.noticeType" label="短信" />
        <div class="message-tips">
          【一指阁】{店铺名称} 为您送上了{优惠券数量}
          张优惠券，已悄悄放入您的账户中，赶紧打开看看吧{http//xxx}
        </div>
      </el-form-item>

      <el-form-item label="">
        <div class="save-btn">
          <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
        </div>
      </el-form-item>
    </el-form>

    <clientDialog
      @changeUser="handleChangeUser"
      @changeDialogVisible="clientChangeDialogVisible"
      :dialogVisible="clientDialogVisible"
    ></clientDialog>
    <!-- <couponDialog
      @changeCoupon="handleChangeCoupon"
      @changeDialogVisible="couponChangeDialogVisible"
      :dialogVisible="couponDialogVisible"
    ></couponDialog> -->
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { rules } from '../../config'
import clientDialog from './cpns/client-dialog/client-dialog.vue'
// import couponDialog from './cpns/coupon-dialog/coupon-dialog.vue'
import { ElMessage } from 'element-plus'
import cache from '@/utils/cache'
import dayjs from 'dayjs'
import { addOpenCoupon ,getCashCouponsList } from '@/service/main/goods'
import emitter from '@/utils/eventbus'
import { useRouter } from 'vue-router'
import { getShopList } from '@/service/main/base'
import {COMPANY_ID} from '@/common/constant'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config'
export default defineComponent({
  props: {

  },
  components: {
    clientDialog,
    PageContent
    // couponDialog
  },
  setup() {
    const router = useRouter()

    const ruleForm = reactive({
      optCouponList: [],
      noticeType: true,
      distributionScope: 1,
      distributionType: 1,
      userIds: [],
      couponList: [],
      sendTime: "",
      name: "",
      dpId:""
    })
    const tabStatusList = reactive([
    {
      value: 0,
      label: '全部'
    },{
      value: 1,
      label: '现金券'
    },{
      value: 2,
      label: '折扣券'
    },{
      value: 3,
      label: '兑换券'
    }])
    const dataList = ref([])
    const dataCount = ref(0)
    const queryInfo = reactive({
      page: 1,
      pagesize: 10
    })
    const handleCurrentChange = page => {
      queryInfo.page = page
      getCouponList()
    }

    const handleChangeUser = value => {
      ruleForm.userIds = value
      // console.log(value);
    }

    const handleChangeCoupon = value => {
      ruleForm.couponList = value
    }

    const handleClick = async () => {
      getCouponList()
    }

    const activeStatus = ref(0)
    const getCouponList = async () => {
      let params={
        current:queryInfo.page,
        size:queryInfo.pagesize,
        type:activeStatus.value==0?undefined:activeStatus.value
      }
      const res = await getCashCouponsList(params)
      let list = res.data.list
      dataList.value = list
      dataCount.value=Number(res.data.total)
    }

    const couponTotal = ref({
      cashCoupon:0,
      discontCoupon:0,
      exchangeCoupon:0,
    })
    const selectionChange = (value) =>{
      ruleForm.couponList=value.map(item=>{
        return item.id
      });
      let cashCoupon=0;
      let discontCoupon=0;
      let exchangeCoupon=0;
      value.map(item=>{
        if (item.type==1) {
          cashCoupon+=1
        }else if (item.type==2) {
          discontCoupon+=1
        }else if (item.type==3) {
          exchangeCoupon+=1
        }
      })
      couponTotal.value.cashCoupon=cashCoupon;
      couponTotal.value.discontCoupon=discontCoupon;
      couponTotal.value.exchangeCoupon=exchangeCoupon;
    }




    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let obj = { ...ruleForm }
          let { userIds,distributionType,sendTime, couponList,noticeType, distributionScope,dpId,name } = obj

          if (distributionScope == 1 && userIds.length == 0) {
            ElMessage({
              message: '请选择客户！',
              type: 'warning',
            })
            return
          }

          if (couponList.length == 0) {
            ElMessage({
              message: '请选择优惠券！',
              type: 'warning',
            })
            return
          }else if (couponList.length > 15) {
            ElMessage({
              message: '优惠券最多可选15张！',
              type: 'warning',
            })
            return
          }

          let userIdArr = []
          userIds.forEach(ele => {
            userIdArr.push(ele.userId)
          })

          let params = {
            name,
            distributionType,
            sendTime:distributionType==1?undefined:sendTime,
            userIds: distributionScope==2?[]:userIdArr,
            couponIds: couponList,
            distributionScope,
            dpId,
            noticeType:noticeType?1:0
          }
          // console.log(params);
          const res = await addOpenCoupon(params)
          if (res.code == 0) {
            router.push({
              path: "/main/coupon/maketingCouponList"
            })
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })

          }else{
            ElMessage({
              message: res.msg,
              type: 'error',
            })
          }
          // console.log('submit!')
        } else {
          console.log('error submit!', fields)
        }
      })


    }

    const clientDialogVisible = ref(false)
    const clientChangeDialogVisible = flag => {
      clientDialogVisible.value = flag
    }
    const handleAddClientClick = () => {
      clientDialogVisible.value = true
    }

    const couponDialogVisible = ref(false)
    const couponChangeDialogVisible = flag => {
      couponDialogVisible.value = flag
    }
    const handleAddCouponClick = flag => {
      couponDialogVisible.value = true
    }

    const handleDeleteClick = (item, index) => {
      // console.log(item);
      // console.log(index);
      ruleForm.userIds.splice(index, 1)
    }

    const ruleFormRef = ref()

    const shopList=ref([])
    const initPage = async () => {
      const res = await getShopList(COMPANY_ID)
      let list=res.data.list
      list.unshift({
        shopId:0,
        shopName:"总部"
      })
      shopList.value=list
      getCouponList()
    }
    initPage ()

    return {
      shopList,
      ruleFormRef,
      handleDeleteClick,
      handleChangeCoupon,
      handleAddCouponClick,
      couponDialogVisible,
      couponChangeDialogVisible,
      handleAddClientClick,
      clientChangeDialogVisible,
      clientDialogVisible,
      ruleForm,
      rules,
      handleChangeUser,
      handleSaveClick,
      tabStatusList,
      dataList,
      dataCount,
      queryInfo,
      handleCurrentChange,
      getCouponList,
      handleClick,
      activeStatus,
      selectionChange,
      contentTableConfig,
      couponTotal
    }

  }
})
</script>

<style scoped lang="less">
.base-form {
  font-size: 12px;

  .open-time {
    display: flex;

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .active-name {
    &:deep(.el-input) {
      max-width: 240px;
    }
  }

  .user-item {
    display: flex;
    align-items: center;
    line-height: 10px;

    .uname {
      .nick {
        margin-bottom: 4px;
      }

      .phone {
        font-size: 12px;
        color: #999;
      }
    }

    img {
      width: 36px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  .save-btn {
    margin-top: 20px;
  }

  .message-tips {
    line-height: 46px;
    background-color: rgb(248, 248, 248);
    padding: 0 10px;
    color: rgb(129, 127, 127);
    font-size: 12px;
  }

  .add-coupon {
    display: flex;
    color: rgb(153, 153, 153);

    .btn {
      margin-right: 12px;
      font-size: 14px;
      cursor: pointer;
      color: rgb(133, 88, 250);
    }
  }

  .coupon-list {
    margin-top: 20px;
    border: 1px solid rgb(247, 248, 250);

    .coupon-item {
      display: flex;

      & > div {
        flex: 1;
      }

      .use-time {
        flex: 3;
      }
    }

    .list {
      min-height: 60px;

      .empty-coupon:hover {
        background-color: rgb(246, 244, 255);
      }

      .empty-coupon {
        line-height: 60px;
        color: rgb(200, 201, 204);
        text-align: center;
      }
    }

    .titles {
      display: flex;
      line-height: 40px;
      background-color: rgb(247, 248, 250);
      padding: 0 10px;

      .use-time {
        flex: 3;
      }

      & > div {
        flex: 1;
      }
    }
  }

  .opt-client-list {
    background-color: rgb(248, 248, 248);
    padding: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-height: 88px;

    .user-wrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-bottom: 10px;
    }

    & > div {
      margin-right: 2.5%;
      width: 30%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;
      color: #817f7f;
      cursor: pointer;
    }

    .add-btn {
      margin-bottom: 10px;
    }
  }

  &:deep(.el-form) {
    max-width: 1400px;
  }
}
</style>
